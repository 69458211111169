<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.zalo_oa_manage") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 305px)"
        class="table-padding-2-no-top"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center">
                <InputFilter
                  :label="$t('labels.name')"
                  :placeholder="$t('labels.name')"
                  name="name"
                  sort-name="name"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="width: 150px">
                <SelectFilter
                  :options="statusOptions"
                  :label="$t('labels.status')"
                  :placeholder="$t('labels.status')"
                  name="status"
                  sort-name="status"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="width: 130px">Token</th>
              <th class="text-center" style="width: 130px">
                <v-btn small color="primary" @click="addItem" class="mr-2">{{
                  $t("labels.add")
                }}</v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="item.id"
              class="text-center"
            >
              <td class="">
                <span v-if="!item.editing">{{ item.name }}</span>
                <v-text-field
                  v-else
                  v-model="item.name"
                  :disabled="!item.editing"
                  class="c-input-small input-disabled-bold"
                  :placeholder="$t('labels.name')"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td class="text-center">
                <span v-if="!item.editing">
                  {{ item.status ? "Hoạt động" : "Dừng hoạt động" }}
                </span>
                <div v-else class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="item.status"
                    :disabled="!item.editing"
                    class="mt-0 ml-1"
                    :value="1"
                    hide-details
                  ></v-checkbox>
                </div>
              </td>
              <td class="text-center">
                <span v-if="item.id">
                  {{ $t(`labels.token_status_${item.token_status}`) }}
                </span>
              </td>
              <td class="text-center">
                <template v-if="!item.editing">
                  <v-btn
                    class="mr-1"
                    x-small
                    color="warning"
                    @click="toggleEditing(item, index, true)"
                  >
                    {{ $t("labels.edit") }}
                  </v-btn>
                  <template v-if="item.id">
                    <a
                      v-if="zaloOauthLink"
                      :href="authLink(item)"
                      class="v-btn v-btn--outlined theme--light v-size--x-small primary--text"
                      target="_blank"
                    >
                      {{ $t("labels.connect") }}
                    </a>
                  </template>
                </template>

                <template v-else>
                  <v-btn
                    class="mr-1"
                    x-small
                    color="success"
                    @click="saveItem(item)"
                    >{{ $t("labels.save") }}</v-btn
                  >
                  <v-btn
                    class="mr-1"
                    x-small
                    color="error"
                    @click="toggleEditing(item, index, false)"
                  >
                    {{ $t("labels.cancel") }}
                  </v-btn>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-dialog v-model="templateDialog" persistent max-width="800px">
        <ZaloZnsTemplate
          v-if="templateDialog"
          @cancel="hideDialogTemplate"
          :zalo="updatingItem"
        />
      </v-dialog>

      <v-dialog v-model="configDialog" persistent max-width="1366px">
        <ZaloZnsConfig v-if="configDialog" @cancel="hideDialogConfig" />
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { ACTIVE_OPTIONS } from "@/libs/const";
import moment from "moment";

export default {
  name: "ZaloOA",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    ZaloZnsTemplate: () => import("@/components/account/ZaloZnsTemplate"),
    ZaloZnsConfig: () => import("@/components/account/ZaloZnsConfig"),
  },
  data: () => ({
    isLoading: false,
    filters: {},
    items: [],
    updatingItem: {},
    zaloOauthLink: null,
    statusOptions: [...ACTIVE_OPTIONS],
    templateDialog: false,
    configDialog: false,
  }),
  watch: {
    filters: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  computed: {
    authLink() {
      return (item) =>
        `${this.zaloOauthLink}&state=${this.generateRandomString(5)}_${
          item.id
        }`;
    },
  },
  created() {},
  mounted() {
    this.getList();
    this.getEMarketAuthLink();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    cancel() {
      this.$emit("cancel");
    },
    addItem() {
      const item = {
        editing: true,
        id: null,
        status: 1,
        name: null,
        token_status: "",
      };
      this.items.unshift(item);
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    showDialogTemplate(item) {
      this.updatingItem = { ...item };
      this.templateDialog = true;
    },
    hideDialogTemplate() {
      this.updatingItem = {};
      this.templateDialog = false;
    },
    showDialogConfig() {
      this.configDialog = true;
    },
    hideDialogConfig() {
      this.configDialog = false;
    },
    async saveItem(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-zalo-oa", item);
        this.isLoading = false;
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async getList() {
      try {
        const { data } = await httpClient.post(
          "/get-all-zalo-oa",
          this.filters
        );
        this.items = [...data].map((item) => {
          let token_status = "";
          if (item.access_token_expired_time) {
            const now = moment().unix();
            const accessExpired = moment(item.access_token_expired_time).unix();
            token_status = accessExpired > now ? 1 : 0;
          }
          return {
            ...item,
            token_status,
          };
        });
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async getEMarketAuthLink() {
      const { data } = await httpClient.post("/get-zalo-oa-link");
      this.zaloOauthLink = data;
    },
  },
};
</script>

<style scoped></style>
